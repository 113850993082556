import { render, staticRenderFns } from "./required.vue?vue&type=template&id=0c2b9872&scoped=true&"
import script from "./required.vue?vue&type=script&lang=js&"
export * from "./required.vue?vue&type=script&lang=js&"
import style0 from "./required.vue?vue&type=style&index=0&id=0c2b9872&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2b9872",
  null
  
)

export default component.exports